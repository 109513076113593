<template>
  <div>

    <v-alert color="gifty" text class="mt-3 rounded-lg" border="bottom">

      <p class="mb-1">
        <strong>Lorem ipsum dolor </strong> sit amet, consectetur adipiscing elit. Vestibulum sed varius odio.
      </p>

      <v-chip-group mandatory color="gifty" v-model="investigationIndex">
        <draggable v-model="campaign.investigations"
                   class="d-flex flex-wrap gap-2"
                   :force-fallback="true"
                   :scroll-sensitivity="100"
                   :options="{ animation: 300, handle: '.handle' }">
          <div v-for="(investigation, i) in campaign.investigations" :key="i">
            <v-chip color="transparent"
                    text-color="gifty"
                    label
                    filter>
              {{ investigation.name.fr }}
              <v-icon right class="handle">mdi-drag-vertical</v-icon>
            </v-chip>
          </div>
        </draggable>

      </v-chip-group>


    </v-alert>

    <draggable v-model="campaign.investigations[investigationIndex].tasks"
               :force-fallback="true"
               :scroll-sensitivity="100"
               :options="{animation:300, handle:'.handle'}"
    >
      <div v-for="(task, i) in campaign.investigations[investigationIndex].tasks" :key="i">
        <v-card class="shadow rounded-lg mt-3">

          <v-card-text class="px-4 px-lg-15 py-10">
            <v-row>

              <v-col :cols="task.answer_type_key === 'multiple_images' ? 4 : 6">
                <p class="font-weight-medium mb-2 required">
                  Type de question
                </p>
                <v-autocomplete :items="question_types"
                                v-model="task.question_type_key"
                                item-value="key"
                                item-text="name.fr"
                                filled
                                hide-details="auto">

                  <template v-slot:selection="data">
                    <v-icon left color="primary">{{ data.item.icon }}</v-icon>
                    {{ data.item.name.fr }}
                  </template>

                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-icon>
                        <v-icon>{{ data.item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name.fr"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>

                </v-autocomplete>
              </v-col>

              <v-col :cols="task.answer_type_key === 'multiple_images' ? 4 : 6">

                <p class="font-weight-medium mb-2 required">
                  Type de réponse
                </p>

                <v-autocomplete :items="answer_types"
                                v-model="task.answer_type_key"
                                item-value="key"
                                item-text="name.fr"
                                filled
                                hide-details="auto">

                  <template v-slot:selection="data">
                    <v-icon left color="primary">{{ data.item.icon }}</v-icon>
                    {{ data.item.name.fr }}
                  </template>

                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-icon>
                        <v-icon>{{ data.item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name.fr"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>

                </v-autocomplete>
              </v-col>

              <v-col cols="4" v-if="task.answer_type_key === 'multiple_images'">
                <p class="font-weight-medium mb-2 required">
                  Nombre des photos oblégatoir
                </p>
                <v-text-field hide-details="auto"
                              v-model="task.number_of_photos"
                              filled
                              placeholder="..."></v-text-field>
              </v-col>


              <!---------Questions------->

              <v-col cols="12">

                <v-row>

                  <v-col cols="12" v-if="task.question_type_key !== 'text'">
                    <p class="font-weight-medium mb-2 required">
                      {{ question_types.find(el => el.key === task.question_type_key).description['fr'] }}
                    </p>
                    <v-file-input outlined
                                  ref="file"
                                  :clearable="false"
                                  :loading="isLoading"
                                  :disabled="isLoading"
                                  @change="uploadFile($event,i)"
                                  show-size
                                  hide-details
                                  chips
                                  small-chips
                                  counter dense
                                  :placeholder="'Cliquez et ' + question_types.find(el => el.key === task.question_type_key).description['fr']"/>

                    <div class="text-center mt-3" v-if="task.file">
                      <v-chip text-color="info"
                              :style="{backgroundColor: 'rgba(34,158,217,0.15)'}"
                              :href="FILE_URL + task.file"
                              target="_blank">
                        <v-icon left small color="info">mdi-check</v-icon>
                        {{ task.file }}
                        <v-icon right small @click="task.file = ''" color="info">mdi-close-circle</v-icon>
                      </v-chip>
                    </div>

                  </v-col>

                  <v-col cols="12">
                    <p class="font-weight-medium mb-2 required">
                      Question
                    </p>
                    <v-textarea v-model="task.question.fr"
                                rows="2"
                                outlined
                                hide-details="auto"
                                dense
                                placeholder="Entrez la question"/>
                  </v-col>

                  <v-col cols="12">
                    <p class="font-weight-medium mb-2 required ar">
                      السؤال
                    </p>
                    <v-textarea v-model="task.question.ar"
                                rows="2"
                                outlined
                                class="ar"
                                dense
                                hide-details="auto"
                                placeholder="أدخل السؤال"
                    />
                  </v-col>

                </v-row>

              </v-col>


              <!---------Responses------->

              <v-col cols="12">

                <v-alert color="info"
                         text
                         :icon="answer_types.find(el => el.key === task.answer_type_key).icon">
                  {{ answer_types.find(el => el.key === task.answer_type_key).description['fr'] }}
                </v-alert>

                <div v-if="task.answer_type_key === 'radio' || task.answer_type_key === 'checkbox'"
                     class="table-border rounded-lg pa-4">

                  <v-row align="center">
                    <v-col cols="5">
                      <p class="font-weight-medium mb-2 required">
                        Option
                      </p>
                    </v-col>
                    <v-col cols="5">
                      <p class="font-weight-medium mb-2 required ar">
                        الخيار
                      </p>
                    </v-col>
                  </v-row>

                  <draggable :options="{animation:300, handle:'.handle'}"
                             :force-fallback="true"
                             :scroll-sensitivity="100"
                             v-model="task.options">
                    <div v-for="(option,indexOption) in task.options" :key="indexOption">
                      <v-row align="center">
                        <v-col cols="5">
                          <v-text-field v-model="option.option.fr"
                                        :prepend-inner-icon="answer_types.find(el => el.key === task.answer_type_key).icon"
                                        outlined
                                        hide-details="auto"
                                        :placeholder="'Entrez l\'option '+(indexOption+1)"
                                        dense/>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field v-model="option.option.ar"
                                        :prepend-inner-icon="answer_types.find(el => el.key === task.answer_type_key).icon"
                                        outlined
                                        class="ar"
                                        :placeholder="'أدخل الخيار '+(indexOption+1)"
                                        hide-details="auto"
                                        dense/>
                        </v-col>
                        <v-col cols="2">
                          <div class="d-flex align-center">
                            <v-tooltip top color="primary">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       icon
                                       v-on="on"
                                       @click="dulicateQuestion(i,option)">
                                  <v-icon size="22">mdi-content-duplicate</v-icon>
                                </v-btn>
                              </template>
                              <span>Dubliquer</span>
                            </v-tooltip>

                            <v-tooltip top color="red">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       :disabled="task.options.length <= 2"
                                       icon
                                       color="red"
                                       v-on="on" @click="deleteQuestion(i,indexOption)">
                                  <v-icon size="22">mdi-delete-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Supprimer</span>
                            </v-tooltip>

                            <v-tooltip top color="primary" v-if="task.options.length > 1">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       class="handle"
                                       icon
                                       v-on="on">
                                  <v-icon size="22">mdi-drag-vertical</v-icon>
                                </v-btn>
                              </template>
                              <span>Glisser</span>
                            </v-tooltip>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </draggable>

                  <div class="text-center mt-3">
                    <v-btn fab
                           text
                           small
                           class="v-btn--active"
                           color="primary"
                           @click="addQuestion(i)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>


            </v-row>
          </v-card-text>

          <v-divider/>

          <v-card-actions>

            <v-btn color="gifty"
                   fab
                   dark
                   small
                   depressed>
               <span class="f-15">
                  {{ i + 1 }}
               </span>
            </v-btn>

            <v-spacer/>

            <v-btn text
                   color="red"
                   small
                   @click="deleteTask(i)"
                   :disabled="campaign.investigations[investigationIndex].tasks.length === 1">
              <v-icon left>mdi-delete-outline</v-icon>
              Supprimer
            </v-btn>

            <v-btn text
                   color="grey darken-2"
                   @click="duplicateTask(task)"
                   small>
              <v-icon left>mdi-content-copy</v-icon>
              Dupliquer
            </v-btn>

            <v-btn text
                   color="grey darken-2"
                   class="cursor-move handle"
                   small>
              <v-icon left>mdi-drag-vertical</v-icon>
              Glisser
            </v-btn>

          </v-card-actions>

        </v-card>
      </div>

    </draggable>

    <div class="text-center mt-3">
      <v-btn fab color="primary" depressed small @click="addTask">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import {HTTP} from "@/http-common";

export default {
  components: {
    draggable
  },
  props: ['campaign', 'question_types', 'answer_types'],
  data() {
    return {
      investigationIndex: 0,
      isLoading: false,
      FILE_URL: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    addTask() {
      this.$vuetify.goTo(document.body.scrollHeight - 350)
      this.campaign.investigations[this.investigationIndex].tasks.push({
        question_type_key: "text",
        answer_type_key: "checkbox",
        question: {},
        number_of_photos: "",
        file: "",
        options: [
          {option: {}},
          {option: {}},
        ],
      })
    },
    deleteTask(i) {
      this.campaign.investigations[this.investigationIndex].tasks.splice(i, 1)
    },
    duplicateTask(task) {
      this.$vuetify.goTo(document.body.scrollHeight - 350)
      this.campaign.investigations[this.investigationIndex].tasks.push(JSON.parse(JSON.stringify(task)))
    },
    deleteQuestion(taskIndex, optionIndex) {
      this.campaign.investigations[this.investigationIndex].tasks[taskIndex].options.splice(optionIndex, 1)
    },
    dulicateQuestion(taskIndex, option) {
      this.campaign.investigations[this.investigationIndex].tasks[taskIndex].options.push(JSON.parse(JSON.stringify(option)))
    },
    addQuestion(taskIndex) {
      this.campaign.investigations[this.investigationIndex].tasks[taskIndex].options.push({
        option: {}
      })
    },
    uploadFile(file, taskIndex) {
      this.isLoading = true
      let formData = new FormData()
      formData.append('file', file)
      HTTP.post('/v1/opus/campaigns/upload/file', formData).then((res) => {
        this.isLoading = false
        this.campaign.investigations[this.investigationIndex].tasks[taskIndex].file = res.data.data
      }).catch(err => {
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.isLoading = false
        console.log(err)
      })
    },
  }
}
</script>

<style>
.v-slide-group__next .v-icon {
  color: #d60c56 !important;
}

.v-slide-group__prev .v-icon {
  color: #d60c56 !important;
}
</style>