<template>
  <div>
    <v-dialog fullscreen v-model="dialog">

      <v-overlay :value="isLoading"
                 color="white"
                 opacity="0.5">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-card>
        <v-card-title>
          Affecter les points de vente
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-row justify="center">
            <v-col cols="8">
              <v-autocomplete :items="databases"
                              v-model="database_ids"
                              multiple
                              chips
                              small-chips
                              deletable-chips
                              clearable
                              counter
                              prepend-icon="mdi-filter"
                              label="Filtrer par une base de données"
                              outlined
                              item-text="name"
                              item-value="id"></v-autocomplete>
            </v-col>
          </v-row>

          <div class="text-center">
            <v-btn color="gifty"
                   :disabled="!database_ids.length"
                   @click="getSalepoints"
                   depressed
                   class="rounded-lg white--text">
              <v-icon left>mdi-magnify</v-icon>
              Recherche
            </v-btn>
          </div>

          <div>
            <v-text-field :style="{width : '350px'}"
                          :disabled="!database_ids.length"
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          class="mt-10"
                          filled
                          placeholder="Rechercher..."
                          rounded

            />

            <div v-if="salepoints.length">
              <v-progress-linear v-if="searchLoading" color="gifty" indeterminate rounded/>
              <v-simple-table class="table-border rounded-lg">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>
                      <v-checkbox
                          class="pa-0 ma-0"
                          hide-details
                          color="success"
                          :value="allSelected"
                          @change="toggleSelectAll"
                      ></v-checkbox>
                    </th>
                    <th>Code</th>
                    <th>Nom</th>
                    <th>Téléphone</th>
                    <th>Wilaya</th>
                    <th>BDD</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(salepoint ,index) in salepoints" :key="index">
                    <td>
                      <v-checkbox class="pa-0 ma-0"
                                  hide-details
                                  color="success"
                                  :value="campaign.investigations[investigationIndex].client_salepoint_ids.includes(salepoint.id)"
                                  @change="toggleSalepointSelection(salepoint.id)"></v-checkbox>
                    </td>
                    <td>{{ salepoint.code }}</td>
                    <td>{{ salepoint.pos_name }}</td>
                    <td>{{ salepoint.owner_phone }}</td>
                    <td>{{ salepoint.wilaya?.name }}</td>
                    <td>
                      <v-chip text-color="gifty" class="bg-subtle-gifty" small>
                        <v-icon small left color="gifty">mdi-database-outline</v-icon>
                        {{ salepoint.database.name }}
                      </v-chip>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <div v-else>
              <v-row align="center" justify="center">
                <v-col cols="7">
                  <v-alert type="info" text class="my-10">
                    Aucun point de vente affiché ! Veuillez utiliser le filtre ci-dessous.
                  </v-alert>
                </v-col>
              </v-row>
            </div>
          </div>

          <v-card-actions>

            <div :style="{width: '120px'}">
              <v-select v-model="limit"
                        :items="[10,20,30,50,100,200,300,400,500]"
                        dense
                        hide-details
                        label="Ligne par page"
                        outlined></v-select>
            </div>

            <v-spacer/>

            <v-pagination v-model="pagination.current"
                          :length="pagination.total"
                          circle
                          total-visible="8"
                          @input="getSalepoints"></v-pagination>

          </v-card-actions>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['campaign'],
  data() {
    return {
      dialog: false,
      searchLoading: false,
      isLoading: false,
      investigationIndex: 0,
      databases: [],

      keyword: null,
      database_ids: [],
      salepoints: [],
      total: 0,
      limit: 10,
      pagination: {
        current: 1,
        total: 0
      },
    }
  },
  methods: {
    open(investigationIndex) {
      if (!this.databases.length) {
        this.getDatabases()
      }
      this.dialog = true
      this.investigationIndex = investigationIndex
    },
    getDatabases() {
      this.isLoading = true
      HTTP.get('/v1/opus/campaigns/databases').then((res) => {
        this.databases = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    getSalepoints(isLoading = true) {
      if (isLoading) {
        this.isLoading = true
      } else {
        this.searchLoading = true
      }
      HTTP.get('/v1/opus/campaigns/salepoints?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          limit: this.limit,
          database_ids: this.database_ids,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.salepoints = res.data.data.data
        this.isLoading = false
        this.searchLoading = false
      }).catch(err => {
        this.isLoading = false
        this.searchLoading = false
        console.log(err)
      })
    },
    toggleSalepointSelection(salepointId) {
      const ids = this.campaign.investigations[this.investigationIndex].client_salepoint_ids;
      const index = ids.indexOf(salepointId);

      if (index === -1) {
        ids.push(salepointId);
      } else {
        ids.splice(index, 1);
      }
    },
    toggleSelectAll() {
      const ids = this.salepoints.map((salepoint) => salepoint.id);
      const selectedIds = this.campaign.investigations[this.investigationIndex].client_salepoint_ids;

      if (this.allSelected) {
        this.campaign.investigations[this.investigationIndex].client_salepoint_ids = selectedIds.filter(
            (id) => !ids.includes(id)
        );
      } else {
        this.campaign.investigations[this.investigationIndex].client_salepoint_ids = [...new Set([...selectedIds, ...ids])];
      }
    },
  },
  computed: {
    allSelected() {
      const selectedIds = this.campaign.investigations[this.investigationIndex].client_salepoint_ids;
      return this.salepoints.every((salepoint) => selectedIds.includes(salepoint.id));
    },
  },
  watch: {
    limit() {
      this.pagination.current = 1
      this.getSalepoints();
    },
    keyword() {
      this.getSalepoints(false);
    },
  }
}
</script>

<style scoped>
.bg-subtle-gifty {
  background-color: rgba(214, 12, 86, 0.15) !important;
}
</style>