<template>
  <div>
    <v-alert type="info" text class="mt-3 rounded-lg">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed varius odio.
    </v-alert>

    <v-card class="shadow rounded-lg mt-3" v-for="(investigation,i) in campaign.investigations" :key="i">

      <v-card-text class="px-4 px-lg-15 py-10">
        <v-row>

          <v-col cols="6">
            <p class="font-weight-medium mb-2 required">
              Titre
            </p>
            <v-text-field v-model="investigation.name.fr"
                          outlined
                          hide-details="auto"
                          dense
                          placeholder="Titre de l'enquête"
            />
          </v-col>

          <v-col cols="6">
            <p class="font-weight-medium mb-2 required ar">
              العنوان
            </p>
            <v-text-field v-model="investigation.name.ar"
                          outlined
                          dense
                          class="ar"
                          hide-details="auto"
                          placeholder="عنوان التحقيق"
            />
          </v-col>

          <v-col cols="6">
            <p class="font-weight-medium mb-2 required">
              Date de début
            </p>
            <v-text-field v-model="investigation.start_date"
                          type="date"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder="Date de début"/>
          </v-col>

          <v-col cols="6">
            <p class="font-weight-medium mb-2 required">
              Date de fin
            </p>
            <v-text-field v-model="investigation.end_date"
                          type="date"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder="Date de fin"/>
          </v-col>

          <v-col cols="12">
            <p class="font-weight-medium mb-2 required">
              Description
            </p>
            <v-textarea v-model="investigation.description.fr"
                        rows="2"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="Description de l'enquête"/>
          </v-col>

          <v-col cols="12">
            <p class="font-weight-medium mb-2 required ar">
              الوصف
            </p>
            <v-textarea v-model="investigation.description.ar"
                        rows="2"
                        class="ar"
                        hide-details="auto"
                        outlined
                        dense
                        placeholder="وصف التحقيق"/>
          </v-col>

          <v-col cols="12" class="mb-0 pb-0">
            <p class="font-weight-medium mb-2">
              Photos (Sélection multiple)
            </p>
            <v-file-input outlined
                          ref="image"
                          :clearable="false"
                          :disabled="isLoading"
                          :loading="isLoading"
                          accept="image/png,image/jpeg"
                          @change="uploadPhotos($event,i)"
                          multiple
                          show-size
                          chips
                          small-chips
                          counter dense
                          placeholder="Photos de l'enquête(png.jpeg,svg)"/>

            <div v-if="investigation.images && investigation.images.length">
              <div v-for="(image,index) in investigation.images"
                   :key="index"
                   class="d-flex justify-space-between pa-3 rounded grey lighten-4 mb-2">
                <v-avatar size="40" rounded>
                  <v-img :src="FILE_URL + image" class="rounded cursor-pointer"/>
                </v-avatar>
                <v-spacer/>
                <v-btn color="red" icon @click="removePhoto(index,i)">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </div>
            </div>

          </v-col>

          <v-col cols="12">
            <p class="font-weight-medium mb-2 required">
              Points gagnants
            </p>
            <v-text-field v-model="investigation.winning_points"
                          hide-details="auto"
                          outlined
                          dense
                          placeholder="Points gagnants de l'enquête"
                          suffix="Pts"/>
          </v-col>

          <v-col cols="12">
            <v-switch v-model="investigation.is_active"
                      class="pt-0 pa-0 ma-0"
                      color="success"
                      label="Active ?"></v-switch>

            <v-switch class="pt-0 pa-0 ma-0"
                      v-model="investigation.for_all_salepoints"
                      color="success"
                      label="Envoyer à tous les points de vente ?"></v-switch>

            <v-tabs v-if="!investigation.for_all_salepoints">
              <v-tab class="text-none" @click="$refs.assignmentDialog.open(i)">
                ({{investigation.client_salepoint_ids.length}}) Affecter les points de vente *
                <v-icon right>mdi-arrow-right</v-icon>
              </v-tab>
            </v-tabs>

          </v-col>


        </v-row>
      </v-card-text>

      <v-divider/>

      <v-card-actions>

        <v-btn color="gifty"
               fab
               dark
               small
               depressed>
               <span class="f-15">
                  {{ i + 1 }}
               </span>
        </v-btn>

        <v-spacer/>

        <v-btn text
               color="red"
               small
               :disabled="campaign.investigations.length === 1"
               @click="deleteInvestigation(i)">
          <v-icon left>mdi-delete-outline</v-icon>
          Supprimer
        </v-btn>

        <v-btn text
               color="grey darken-2"
               small
               @click="duplicateInvestigation(investigation)">
          <v-icon left>mdi-content-copy</v-icon>
          Dupliquer
        </v-btn>

      </v-card-actions>

    </v-card>

    <div class="text-center mt-3">
      <v-btn fab
             color="primary"
             depressed
             small
             @click="addInvestigation">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <AssignmentDialog ref="assignmentDialog" :campaign="campaign"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import AssignmentDialog from './AssignmentDialog'

export default {
  props: ['campaign'],
  components: {
    AssignmentDialog
  },
  data() {
    return {
      isLoading: false,
      errors: [],
      FILE_URL: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    uploadPhotos(files, investigationIndex) {
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          this.isLoading = true
          this.errors = []
          let formData = new FormData()
          formData.append('image', files[i])
          HTTP.post('/v1/opus/campaigns/upload/image', formData).then((res) => {
            this.isLoading = false
            this.campaign.investigations[investigationIndex].images.push(res.data.data)
          }).catch(err => {
            this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
            this.errors = err.response.data.errors
            this.isLoading = false
            console.log(err)
          })
        }
      }
    },
    removePhoto(photoIndex, investigationIndex) {
      this.campaign.investigations[investigationIndex].images.splice(photoIndex, 1)
    },
    addInvestigation() {
      this.$vuetify.goTo(document.body.scrollHeight - 350)
      this.campaign.investigations.push({
        name: {
          fr: '',
          ar: '',
        },
        start_date: '',
        end_date: '',
        description: {
          fr: '',
          ar: '',
        },
        images: [],
        winning_points: '',
        for_all_salepoints: false,
        client_salepoint_ids: [],
        is_active: true,
        tasks: [
          {
            question_type_key: "text",
            answer_type_key: "checkbox",
            question: {},
            number_of_photos: "",
            file: "",
            options: [
              {option: {}},
              {option: {}},
            ],
          }
        ],
      })
    },
    deleteInvestigation(i) {
      this.campaign.investigations.splice(i, 1)
    },
    duplicateInvestigation(investigation) {
      this.$vuetify.goTo(document.body.scrollHeight - 350)
      this.campaign.investigations.push(JSON.parse(JSON.stringify(investigation)))
    },
  }
}
</script>

<style scoped>

</style>